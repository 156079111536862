<i18n src='./lang.json'></i18n>
<template>
    <ul id="dataUploadGrid">
        <li class="dataUploadColumn1">
            <cs-widget-card style="margin: 2% auto" :widgetCardTitle="$t('uploadArea')"
                            :widget-card-text-wrapper-style="{'padding-top': '55px'}"
            >
                <template slot="quickAction">
                    <cs-action-button :action="uploadFiles" :disabled="!fileRecords.length"
                                      :icon="$csBasicsIcons.uploadCloud"
                                      :tooltip="uploadButtonText"></cs-action-button>
                </template>
                <template slot="widgetCardText">
                    <div class="uploadareabody">
                        <div class="uploadarea" v-cloak @drop.prevent="addFile" @dragover.prevent>
                            <VueFileAgent
                                ref="vueFileAgent"
                                :accept="'.csv'"
                                :multiple="true"
                                :helpText="$t('fileAgentHelpText')"
                                :meta="true"
                                :deletable="true"
                                v-model="fileRecords"
                                @beforedelete="removeFromList($event)"
                            >
                            </VueFileAgent>
                        </div>
                    </div>
                </template
                >
            </cs-widget-card>
        </li>
        <li class="dataUploadColumn2">
            <cs-widget-card :widgetCardTitle="$t('history')" :hasContextMenu="false"
                            :widget-card-text-wrapper-style="{'padding-top': '55px'}"
            >
                <!-- ISIN PICKER -->
                <template slot="widgetCardText">
                    <cs-perfect-scrollbar height="75vh">
                        <template slot="perfectScrollbarContent">
                            <cs-progress-circular v-if="$apollo.loading"
                                                  :progressColor="$csBasicsColors.csPrimaryDarkBlue"></cs-progress-circular>
                            <v-list v-else-if="!$apollo.loading && uploadHistoryList.length !== 0">
                                <v-list-item v-for="item in uploadHistoryList" :key="item.uuid">
                                    <v-list-item-icon>
                                        <v-icon v-if="item.technical_format === 'csv'"
                                                :color="$csBasicsColors.csPrimaryDarkBlue">
                                            {{ $csBasicsIcons.csvFile }}
                                        </v-icon>
                                        <v-icon
                                            v-else-if="item.technical_format === 'xls' || item.technical_format === 'xlsx'"
                                            :color="$csBasicsColors.csAccentGreen">
                                            {{ $csBasicsIcons.excelFile }}
                                        </v-icon>
                                        <v-icon v-else :color="$csBasicsColors.csAccentGrey">
                                            {{ $csBasicsIcons.csLegal }}
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.identifier }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ getTimeStamp(item.timestamp) }} {{ $t('by') }}
                                            {{ getUserFullName(item.username) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-icon @click="downloadHistoryFile(item.s3_key, item.s3_version)">
                                            {{ $csBasicsIcons.download }}
                                        </v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                            <v-list v-else>
                                <v-list-item>
                                    <v-list-item-icon class="mr-2">
                                        <v-icon :color="$csBasicsColors.csPrimaryDarkBlue">
                                            {{ $csBasicsIcons.informationNew }}
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('noDocuments') }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </template>
                    </cs-perfect-scrollbar>
                </template>
            </cs-widget-card>
        </li>
    </ul>
</template>

<script>
import GraphqlHelper from "@/js-helpers/graphqlHelper";
import axios from "axios";
import Vue from "vue";
import CSBasicsI18n from "@cs/cs-frontend-components/src/cs-basics-i18n";

export default {
    name: "DataUpload",
    data() {
        return {
            getUploadHistory: [],
            getUploadHistoryList: [],
            getUsers: [],
            fileRecords: [],
            loading: true,
        };
    },
    apollo: {
        getUploadHistory: {
            query: GraphqlHelper.getUploadHistoryQuery,
            client: 'CSQUANTENGINE_CLIENT',
            subscribeToMore: {
                document: GraphqlHelper.uploadHistorySubscription,
                updateQuery: (previousResult, {subscriptionData}) => {
                    Vue.console.info("Got update on Upload History");
                    return {
                        getUploadHistory: [
                            ...previousResult.getUploadHistory,
                            subscriptionData.data.insertedHistoryItem,
                        ]
                    };
                },
            },
        },
        getUsers: {
            query: GraphqlHelper.getUsers,
            client: 'BASE_CLIENT',
        },
    },
    methods: {
        updateUploadHistory(uploadHistory) {
            /* Sort Array by timestamp */
            const uploadHistorySorted = [];
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(uploadHistory)) {
                uploadHistorySorted.push(value);
            }
            uploadHistorySorted.sort((a, b) => {
                let aDate = new Date(a.timestamp);
                let bDate = new Date(b.timestamp);
                return aDate.valueOf() - bDate.valueOf();
            });
            this.getUploadHistoryList = uploadHistorySorted.reverse();
            this.loading = false;
        },
        getTimeStamp(timestamp_input) {
            const timestamp = Number(timestamp_input) * 1000;
            const timestampPast = new Date(timestamp);
            const timestampNow = new Date();
            const diff = timestampNow.valueOf() - timestampPast.valueOf();
            return this.timeConversion(diff, timestampPast);
        },
        timeConversion(diff, timestampPast) {
            let seconds = (diff / 1000).toFixed(0);

            let minutes = (diff / (1000 * 60)).toFixed(0);

            let hours = (diff / (1000 * 60 * 60)).toFixed(0);

            let days = (diff / (1000 * 60 * 60 * 24)).toFixed(0);
            let locale = this.$store.getters['langModule/locale'];
            if (seconds < 60) {
                return this.$t('justNow');
            } else if (minutes < 60) {
                return locale === 'de' ? this.$t('ago') + ' ' + minutes + " " + this.$t('minutes')
                    : minutes + " " + this.$t('minutes') + ' ' + this.$t('ago');
            } else if (hours < 24) {
                return locale === 'de' ? this.$t('ago') + ' ' + hours + " " + this.$t('hours')
                    : hours + " " + this.$t('hours') + ' ' + this.$t('ago');
            } else if (days < 8) {
                return locale === 'de' ? this.$t('ago') + ' ' + days + " " + this.$t('days')
                    : days + " " + this.$t('days') + ' ' + this.$t('ago');
            } else {
                return timestampPast.toLocaleString();
            }
        },
        removeFromList: function (fileRecord) {
            let i = this.fileRecords.indexOf(fileRecord);
            this.fileRecords.splice(i, 1);
        },
        async uploadFiles() {
            const i18n = new CSBasicsI18n(require('./lang.json'), this.$store);
            let conciergeDeskURL;
            let authUser;
            //For running in BaseApp
            if (this.$store.getters['csbaseAppIntegrationModule/deployedServices']) {
                conciergeDeskURL =
                    this.$store.getters['csbaseAppIntegrationModule/deployedServices'].csquantengine.parameters.CONCIERGE_DESK_URL;
                authUser = await this.$store.dispatch('csbaseAuthModule/getAuthenticatedUser');
            }
            // For running locally without BaseApp
            else if (process.env.VUE_APP_CONCIERGE_DESK_URL) {
                conciergeDeskURL = process.env.VUE_APP_CONCIERGE_DESK_URL;
                authUser = this.$store.getters.user;
            }
            if (conciergeDeskURL) {
                let url = conciergeDeskURL + "upload/";
                let headers = {
                    Authorization: authUser.signInUserSession.idToken.jwtToken,
                    "Content-Type": "text/csv",
                    "CS_APP_SIGNATURE": "CS-QUANTENGINE",
                    "x-cs-request-user": authUser.username
                };
                for (let i = 0; i < this.fileRecords.length; i++) {
                    let data = this.fileRecords[i].file;
                    let fileName = this.fileRecords[i].name();
                    axios.put(url + fileName, data, {headers}).
                        then(() => {
                            let notification = {
                                title: i18n.t("successUploadTitle"),
                                text: i18n.t("successUploadMessage", {filename: fileName}),
                                type: 'success'
                            };
                            Vue.prototype.$notify(notification);
                        }).
                        catch((error) => {
                            Vue.console.error(error);
                            let notification = {
                                title: i18n.t("failureUploadTitle"),
                                text: i18n.t("failureUploadMessage", {filename: fileName}),
                                type: 'error'
                            };
                            Vue.prototype.$notify(notification);
                        });
                }
                this.fileRecords = [];
                await new Promise((res) => setTimeout(res, 1000));
            }
        },
        forceFileDownload(response, title) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", title);
            document.body.appendChild(link);
            link.click();
        },
        async downloadHistoryFile(key, version) {
            let conciergeDeskURL;
            let authUser;
            //For running in BaseApp
            if (this.$store.getters['csbaseAppIntegrationModule/deployedServices']) {
                conciergeDeskURL =
                    this.$store.getters['csbaseAppIntegrationModule/deployedServices'].csquantengine.parameters.CONCIERGE_DESK_URL;
                authUser = await this.$store.dispatch('csbaseAuthModule/getAuthenticatedUser');
            }
            // For running locally without BaseApp
            else if (process.env.VUE_APP_CONCIERGE_DESK_URL) {
                conciergeDeskURL = process.env.VUE_APP_CONCIERGE_DESK_URL;
                authUser = this.$store.getters.user;
            }
            if (conciergeDeskURL) {
                let url = conciergeDeskURL + "input_history/download/" + key + "?versionId=" + version;
                let headers = {Authorization: authUser.signInUserSession.idToken.jwtToken};
                axios({
                    method: "get",
                    url,
                    headers,
                    responseType: "arraybuffer",
                }).then((response) => {
                    this.forceFileDownload(response, key);
                }).catch((error) => Vue.console.error(error));
            }
        },
        getUserFullName(username) {
            let given_name = 'Unknown';
            let family_name = 'Unknown';
            let user = this.getUsers.find((i) => i.username === username);
            if (user) {
                given_name = user.given_name;
                family_name = user.family_name;
            }
            return given_name + ' ' + family_name;
        },
    },
    computed: {
        uploadHistoryList() {
            return this.getUploadHistoryList;
        },
        uploadButtonText() {
            let returnText = '';
            let locale = this.$store.getters['langModule/locale'];
            if (this.fileRecords.length === 1) {
                returnText = locale === 'de' ? '1 ' + this.$t('file') + ' ' + this.$t('upload')
                    : this.$t('upload') + '1 ' + this.$t('file');
            } else if (this.fileRecords.length > 1) {
                returnText =
                    locale === 'de' ? '' + this.fileRecords.length + ' ' + this.$t('files') + ' ' + this.$t('upload')
                        : this.$t('upload') + ' ' + this.fileRecords.length + ' ' + this.$t('files');
            }
            return returnText;
        }
    },
    watch: {
        getUploadHistory(val) {
            if (val) {
                this.updateUploadHistory(val);
            }
        },
    },
};
</script>

<style lang="scss">
@import "../../styles/main";

#dataUploadGrid {
    display: flex;
    /* These lines can affect the second row of elements to be misplaced */
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    list-style-type: none;

}

.dataUploadColumn1 {
    width: 35%;

    .widgetCardTextWrapper {
        padding-bottom: 10%;
    }
}

.dataUploadColumn2 {
    width: 35%;
    margin: 0 1%;
}

.uploadareabody {
    margin-left: 15px;
    margin-bottom: 25px;
}

.uploadarea button {
    background-color: $cs-primary-dark-blue;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border-radius: 20px;
}

.uploadarea button:disabled {
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border-radius: 20px;
}

#dataUploadButton {
    background: $cs-accent-green;
    float: right;
    text-transform: none;
    color: $cs-light;

    .dataUploadButtonIcon {
        font-size: 34px;
    }
}

.filelist {
    width: 46%;
    display: inline-block;
    margin: 2% 2% 0 1%;
    vertical-align: top;
}

.uploadarea h2,
.filelist h2 {
    text-align: center;
    margin-bottom: 20px;
}

th.text-left {
    text-align: left;
}

.result_symbol {
    font-size: 16pt;
    font-weight: bold;
}

.result_symbol#error {
    color: $cs-accent-red;
}

.result_symbol#success {
    color: $cs-accent-green;
}

.is-drag-valid.is-drag-over .vue-file-agent.file-input-wrapper,
.vue-file-agent.file-input-wrapper.is-drag-valid.is-drag-over {
    border-color: $cs-primary-light-blue !important;
    -webkit-box-shadow: inset 0 0 20px 1px $cs-primary-light-blue !important;
    box-shadow: inset 0 0 20px 1px $cs-primary-light-blue !important;
}
</style>