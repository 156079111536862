/**
 * Providing chartJs charts configurations.
 */
module.exports = {
    /**
     * This method returns a basic implementation of a chartOptions JSON object.
     * @returns {*} Chart Options.
     */
    getBasicChartOptions() {
        return {
            layout: {},
            title: {},
            plugins: {
                datalabels: {
                    display: false,
                },
            },
            responsive: true,
            maintainaspectratio: false,
            legend: {
                display: false,
            },
            animation: {
                duration: 2000,
                easing: 'easeInOutQuad'
            },
            scales: {
                yAxes: [
                    {
                        id: 'left-y-axis',
                        type: 'linear',
                        position: 'left',
                        gridLines: {
                            display: false
                        },
                        ticks: {},
                        scaleLabel: {}
                    },
                ],
                xAxes: [
                    {
                        gridLines: {
                            display: false
                        },
                        ticks: {},
                        scaleLabel: {},
                    }
                ]
            },
            tooltips: {
                enabled: true,
                backgroundColor: "#424242",
                titleFontSize: 16,
                titleFontColor: "#F8f8f8",
                bodyFontColor: "#F8f8f8",
                bodyFontSize: 14,
                position: "nearest",
                displayColors: false,
            }
        };
    },
    /**
     *
     * @param {*} scaleLabel
     * @param fontSize
     */
    setScaleLabel(scaleLabel, fontSize) {
        let returnObj = {};
        if (scaleLabel) {
            returnObj.display = true;
            returnObj.labelString = scaleLabel;
            returnObj.fontSize = fontSize ? fontSize : 14;
        }
        return returnObj;
    },
    /**
     * Sets the animation for the chart
     * @param {*} duration time until the chart is fully displayed
     * @param {*} easing the animation type
     */
    setAnimation(duration, easing) {
        let returnObj = {};
        returnObj.easing = easing ? easing : "easeInOutQuad";
        returnObj.duration = typeof duration == 'number' ? duration : 2000;
        return returnObj;
    },
    /**
     *
     * @param {*} title
     */
    setTitle(title) {
        let returnObj = {};
        returnObj.text = title ? title : "";
        returnObj.display = true;
        returnObj.fontSize = 16;
        returnObj.padding = 30;
        return returnObj;
    },
    /**
     *
     * @param {*} max
     * @param {*} min
     * @param {*} stepSize
     * @param {*} callback
     */
    addSecondYAxis(max, min, callback, stepSize) {
        let returnObj = {};
        returnObj.gridLines = {
            display: false,
        };
        returnObj.ticks = {};
        returnObj.position = 'right';
        returnObj.id = 'right-y-axis';
        returnObj.type = 'linear';
        if (max && min) {
            returnObj.ticks.max = max;
            returnObj.ticks.min = min;
        }
        if (stepSize) {
            returnObj.ticks.stepSize = stepSize;
        }
        if (callback) {
            returnObj.ticks.callback = callback;
        }
        return returnObj;
    }
};
