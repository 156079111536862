import gql from 'graphql-tag';

export const CALCULATION_STATS_QUERY = gql`
query getCalculationsStats($Period: Period!, $numberPeriods: Int!) {
  getCalculationsStats(Period: $Period, numberPeriods: $numberPeriods) {
    calculations
    total
    trend
  }
}`
export const CALCULATION_STATS_VARIABLES = ["Period", "numberPeriods"];