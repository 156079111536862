<i18n src='./lang.json'></i18n>
<template>
    <ul id="dashboardGrid">
        <li class="dashboardColumn1">
            <inflight-calculations></inflight-calculations>
            <health-check></health-check>
        </li>
        <li class="dashboardColumn2">
            <calculation-card></calculation-card>
        </li>
        <li class="dashboardColumn3">
            <!--	<speed-stats></speed-stats>-->
        </li>
    </ul>
</template>

<script>
import InflightCalculations from "@/components/general/cs-inflight-calculations";
import HealthCheck from "@/components/general/cs-health-check";
import CalculationCard from "@/components/general/cs-calculations";

export default {
    name: "cs-dashboard",
    components: {
        InflightCalculations,
        HealthCheck,
        CalculationCard,
        //	SpeedStats,
    },
};
</script>

<style lang="scss">
#dashboardGrid {
    display: flex;
    /* These lines can affect the second row of elements to be misplaced */
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    list-style-type: none;
    margin-right: 15%;
    margin-top: 2.5%;
}

.dashboardColumn1 {
    width: 40%;
}

.dashboardColumn2 {
    width: 35%;
    margin: 0 1%;
}

</style>