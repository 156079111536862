import { render, staticRenderFns } from "./index.vue?vue&type=template&id=68291fc6&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68291fc6",
  null
  
)

/* custom blocks */
import block0 from "./lang.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc775689249%2Fsrc%2Fgit-codecommit.eu-central-1.amazonaws.com%2Fv1%2Frepos%2Fcsquantengine-frontend%2Fsrc%2Fcomponents%2Fgeneral%2Fcs-version-information%2Findex.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VList,VListItem,VListItemContent,VMenu,VSpacer,VToolbar,VToolbarTitle,VTooltip})
