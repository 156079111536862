import gql from 'graphql-tag';

export const USER_INFORMATION_QUERY = gql`
query getUserInformation($username: String!) {
    getUserInformation(username: $username) {
      account_status
      created
      email
      phone_number
      family_name
      given_name
      groups
      last_modified
      username
    }
  }`
export const USER_INFORMATION_VARIABLES = ["username"];