<i18n src='./lang.json'></i18n>
<template>
    <div style="height: 75vh">
        <div class="isinPickerWrapper">
            <v-row class="isinPickerWrapperRow">
                <v-col :cols="isinPickerWidth">
                    <cs-widget-card
                        v-bind:class="[selectedIsin && chartDataLoaded ? isinPickerContainerStyle_Right : isinPickerContainerStyle_Central]"
                        :widgetCardTitle="$t('isinPickerTitle')"
                        :widget-card-text-wrapper-style="{'padding-top': '55px'}"
                        :hasContextMenu="false"
                    >
                        <template slot="widgetCardText"
                        >
                            <v-autocomplete
                                v-model="selectedIsin"
                                :items="isins"
                                :search-input.sync="search"
                                cache-items
                                class="mx-4"
                                flat
                                item-value="id"
                                clearable
                                hide-no-data
                                hide-details
                                :label="$t('chooseISIN')"
                                solo-inverted
                            ></v-autocomplete
                            >
                        </template>
                    </cs-widget-card>
                </v-col>
                <v-col v-if="chartDataLoaded && selectedIsin" cols="6">
                    <cs-widget-card style="width: 60%; margin-left: 2.5%" class="mr-auto"
                                    :widget-card-text-wrapper-style="{'padding-top': '55px'}"
                                    :widgetCardTitle="$t('calculationPickerTitle')" :hasContextMenu="false">
                        <template slot="quickAction">
                            <cs-action-button :tooltip="$t('downloadResults')" class="mr-2" icon-size="26"
                                              :icon="$csBasicsIcons.downloadCloud"
                                              :action="downloadResultJson"></cs-action-button>
                        </template>
                        <template slot="widgetCardText">
                            <v-autocomplete
                                v-model="selectedIsin2"
                                :items="isins2"
                                :item-text="(item) => getAvailableIsinTimestamp(item)"
                                item-value="sk"
                                class="mx-4"
                                flat
                                clearable
                                hide-no-data
                                hide-details
                                :label="$t('chooseCalculation')"
                                solo-inverted
                            ></v-autocomplete
                            >
                        </template>
                    </cs-widget-card>
                </v-col>
            </v-row>
        </div>

        <div v-if="selectedIsin" class="resultGridPresenter text-center mr-15">
            <v-btn fab text disabled class="mt-2">
                <v-icon id="resultGridPresenterIcon">{{ $csBasicsIcons.expandDown }}</v-icon>
            </v-btn>
            <h2 id="resultGridPresenterTitle">{{ $t('Results') }}</h2>
        </div>
        <div class="progressCircleContainer mt-10 mr-15">
            <cs-progress-circular v-if="this.getSelectedIsin && !this.chartDataLoaded"
                                  :progressColor="$csBasicsColors.csPrimaryDarkBlue"></cs-progress-circular>
        </div>
        <ul id="resultGrid" v-if="this.getSelectedIsin && this.chartDataLoaded">
            <li class="resultColumn1">
                <ul id="resultColumn1List">
                    <!-- STATUS CONTAINER -->
                    <li class="statusContainer">
                        <cs-widget-card class="statusWidgetCard" widgetCardTitle="Status"
                                        :widget-card-text-wrapper-style="{'padding-top': '55px'}"
                                        :hasContextMenu="false">
                            <template slot="widgetCardText">
                                <v-expansion-panels flat>
                                    <v-expansion-panel disabled>
                                        <v-expansion-panel-header class="pb-1 pt-1" hide-actions>
                                            <v-row no-gutters>
                                                <v-col cols="1">
                                                    <v-badge style="cursor: auto" bordered dot inline left
                                                             :color="$csBasicsColors.csAccentGreen"
                                                             content=""></v-badge>
                                                </v-col>
                                                <v-col cols="4" style="color: black"> {{
                                                        $stringFormatter.firstUpperThenLowerCase(
                                                            isinInfoObject.General.Status)
                                                    }}
                                                </v-col>
                                                <v-col cols="7" style="color: rgba(0, 0, 0, 0.6); font-size: medium">
                                                    {{ convertStatusTime(isinInfoObject.General.Timestamp) }}
                                                </v-col
                                                >
                                            </v-row>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="pr-0"></v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel disabled v-if="isinInfoObject.General.Generation">
                                        <v-expansion-panel-header hide-actions class="pb-1 pt-1">
                                            <v-row no-gutters>
                                                <v-col cols="1">
                                                    <v-badge style="cursor: auto" bordered dot inline left
                                                             :color="$csBasicsColors.csAccentOrange"
                                                             content=""></v-badge>
                                                </v-col>
                                                <v-col cols="11" style="color: black"> {{
                                                        $t('requiredKidGeneration')
                                                    }}
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="pr-0"></v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel disabled v-if="isinInfoObject.RequestUser">
                                        <v-expansion-panel-header hide-actions class="pb-1 pt-1">
                                            <v-row no-gutters>
                                                <v-col cols="11" style="color: rgba(0, 0, 0, 0.6); font-size: medium">
                                                    {{ $t('calculation') }} {{ $t('initiated') }} {{ $t('by') }}
                                                    {{ isinInfoObject.RequestUser }}
                                                </v-col
                                                >
                                            </v-row>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content class="pr-0"></v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </template>
                        </cs-widget-card>
                    </li>
                    <!-- SRI CONTAINER -->
                    <li class="sriContainer">
                        <!-- SRI RATINGS: 1-7 -->
                        <cs-widget-card :widget-card-text-wrapper-style="{'padding-top': '55px'}"
                                        widgetCardTitle="SRI" :hasContextMenu="false">
                            <!-- ISIN PICKER -->
                            <template slot="widgetCardText">
                                <v-row align="center" justify="center" style="width: 100%; margin: 0 auto">
                                    <v-btn-toggle rounded>
                                        <v-btn
                                            :small="sri!==sriRating"
                                            v-bind:class="[sri === sriRating ? sriButtonSelectedClass : sriButtonClass]"
                                            v-for="sriRating in sriRatings"
                                            v-bind:key="sriRating.id"
                                            :disabled="sri!==sriRating"
                                        >
											<span
                                                v-bind:class="[sri === sriRating ? sriButtonRatingSelectedClass : sriButtonRatingClass]">
												{{ sriRating }}
											</span>
                                        </v-btn>
                                    </v-btn-toggle>
                                    <!-- SRI RATINGS: RISK LEGEND -->
                                </v-row>
                                <v-row align="center" justify="center" style="width: 100%; margin: 0 auto">
                                    <v-col cols="5" style="text-align: right">{{ $t('lowerRisk') }}</v-col>
                                    <v-col cols="2" style="text-align: center">
                                        <i class="fa fa-arrows-alt-h" style="font-size: 2rem" aria-hidden="true"></i>
                                    </v-col>
                                    <v-col cols="5" style="text-align: left">{{ $t('higherRisk') }}</v-col>
                                </v-row>
                            </template>
                        </cs-widget-card>
                    </li>
                </ul>
            </li>
            <li class="resultColumn2">
                <cs-widget-card :widget-card-text-wrapper-style="{'padding-top': '35px'}"
                                :widgetCardTitle="$t('performanceScenarioContainerTitle')" :hasContextMenu="false">
                    <!-- PERFORMANCE SCENARIOS -->
                    <template slot="widgetCardText">
                        <Barchart ref="perfScenarioChart" :chartData="getChartData" :options="chartOptions"
                                  :height="400"></Barchart>
                    </template>
                </cs-widget-card>
            </li>
            <li class="resultColumn3">
                <!-- COST INDICATOR CONTAINER -->
                <cs-widget-card class="costIndicatorContainer"
                                :widget-card-text-wrapper-style="{'padding-top': '55px'}"
                                :widgetCardTitle="$t('costIndicatorContainerTitle')"
                                :hasContextMenu="false">
                    <!-- ISIN PICKER -->
                    <template slot="widgetCardText">
                        <v-row align="center" justify="center" style="width: 100%; margin: 0 auto">
                            <v-simple-table class="costIndicatorTable" v-if="this.getSelectedIsin">
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left subtitle-1">GKIS</th>
                                        <th class="text-left subtitle-1">GKIM</th>
                                        <th class="text-left subtitle-1">GKIF</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr style="border-bottom: none !important">
                                        <td class="text-center" style="font-size: 1.1rem">
                                            {{ "" + costIndicators.gkis + "%" }}
                                        </td>
                                        <td class="text-center" style="font-size: 1.1rem">
                                            {{ "" + costIndicators.gkim + "%" }}
                                        </td>
                                        <td class="text-center" style="font-size: 1.1rem">
                                            {{ "" + costIndicators.gkif + "%" }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-center" style="font-size: 1.1rem">
                                            {{ "" + costIndicators.gkisVal + "€" }}
                                        </td>
                                        <td class="text-center" style="font-size: 1.1rem">
                                            {{ "" + costIndicators.gkimVal + "€" }}
                                        </td>
                                        <td class="text-center" style="font-size: 1.1rem">
                                            {{ "" + costIndicators.gkifVal + "€" }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-row>
                    </template>
                </cs-widget-card>
            </li>
        </ul>
    </div>
</template>

<script>
import Barchart from "@/components/charts/basic/BarChart";
import NumberFormatConverter from "@/js-helpers/numberFormatConverter";
import GraphqlHelper from "@/js-helpers/graphqlHelper";
import ChartHelper from "@/js-helpers/chartHelper";
import axios from "axios";
import { Datalabels } from "chartjs-plugin-datalabels";
import Vue from "vue";


export default {
    name: "results",
    components: {Barchart},
    data() {
        return {
            loading: false,
            isinPickerTitle: "ISIN Picker",
            calculationPickerTitle: "Calculation Picker",
            costIndicatorContainerTitle: "Cost Indicator",
            performanceScenarioContainerTitle: "Performance Scenarios",
            chartDataLoaded: false,
            getIsinInfoObject: {},
            getSri: 1,
            sriRatings: ["", 1, 2, 3, 4, 5, 6, 7, ""],
            sriButtonSelectedClass: "sriButtonSelected",
            sriButtonClass: "sriButton",
            sriButtonRatingSelectedClass: "sriButtonRatingSelected",
            sriButtonRatingClass: "sriButtonRating",
            search: null,
            search2: null,
            selectedIsin: "",
            selectedIsin2: "",
            getIsins: [],
            getIsins2: [],
            chartColors: [],
            chartLabels: [],
            // NEW
            chartData_BarChart_Percentages: [],
            chartData_LineChart_Absolutes: [],
            chartData: {},
            roiValues: [],
            s3key: "",
            s3version: "",
            isinPickerContainerStyle_Right: "isinPickerContainerStyle_Right",
            isinPickerContainerStyle_Central: "isinPickerContainerStyle_Central"
        };
    },
    apollo: {
        $client: 'CSQUANTENGINE_CLIENT', //Define which AppSyncClient to use for the apollo queries
        listCalculatedISINs: {
            query: GraphqlHelper.getListCalculatedISINs(),
        },
    },
    computed: {
        getChartData() {
            return this.chartData;
        },
        chartOptions() {
            const that = this;
            let chartOptionsObj = ChartHelper.getBasicChartOptions();
            let ySecondAxesLabelString = this.$stringFormatter.firstUpperThenLowerCase(this.$t('expected')) +
                " ROI (netto)";
            let yAxesLabelString = this.$t('percentageChange');
            chartOptionsObj.scales.yAxes[0].scaleLabel = ChartHelper.setScaleLabel(yAxesLabelString);
            chartOptionsObj.scales.yAxes[0].ticks.beginAtZero = false;
            chartOptionsObj.scales.yAxes[0].ticks.lineHeight = 2;
            // chartOptionsObj.scales.yAxes[0].ticks.padding = 10;
            let chartTitle =
                this.$t('investmentAmount') + NumberFormatConverter.convertNumberFormat(this.investmentSum) +
                "€, RHP: " + this.convertRHPFormat(this.rhp) + "";
            chartOptionsObj.title = ChartHelper.setTitle(chartTitle);
            chartOptionsObj.scales.yAxes.push({
                id: 'right-y-axis',
                type: 'linear',
                position: 'right',
                gridLines: {
                    display: false
                },
                ticks: {},
                scaleLabel: ChartHelper.setScaleLabel(ySecondAxesLabelString)
            });

            chartOptionsObj.plugins.datalabels = {
                color: "black",
                clamp: true,
                clip: false, // IF true, datalabels that leave the chart are won't get displayed
                offset: 0,
                rotation: that.roiValues.length > 4 ? 270 : 0,
                font: {
                    size: 12,
                    //weight: "bold",
                },
                display: true,
                formatter: function (value) {
                    return "" + NumberFormatConverter.convertNumberFormat(value, 1, 1) + "%";
                },
                // The position of the datalabel relative to the anchor point position and orientation
                // 'center' (default): the label is centered on the anchor point
                // 'start': the label is positioned before the anchor point, following the same direction
                // 'end': the label is positioned after the anchor point, following the same direction
                // 'right': the label is positioned to the right of the anchor point (0°)
                // 'bottom': the label is positioned to the bottom of the anchor point (90°)
                // 'left': the label is positioned to the left of the anchor point (180°)
                // 'top': the label is positioned to the top of the anchor point (270°)
                align: function (context) {
                    let value = context.dataset.data[context.dataIndex];
                    if (Math.sign(value) === 1) {
                        return value < 15 ? "end" : "start";
                    } else if (Math.sign(value) === -1) {
                        return value > -15 ? "bottom" : "start";
                    } else {
                        return "end";
                    }
                },
                // Anchor point, either 'start', 'center' or 'end'
                anchor: function (context) {
                    let value = context.dataset.data[context.dataIndex];
                    // positive value
                    if (Math.sign(value) === 1) {
                        return "end";
                    }
                    // Negative value
                    else if (Math.sign(value) === -1) {
                        return value > -15 ? "start" : "end";
                    } else {
                        return "end";
                    }
                },
            };
            chartOptionsObj.tooltips.callbacks = {
                title: function (tooltipItem) {
                    return tooltipItem[0].label;
                },
                label: function (tooltipItem) {
                    return ["ROI: " + that.roiValues[tooltipItem.index]];
                },
                afterLabel: function (tooltipItem) {
                    return [that.$t('percentageChange') + ': ' +
                    NumberFormatConverter.convertNumberFormat(that.chartData_BarChart_Percentages[tooltipItem.index],
                        2) +
                    "%"];
                },
            };

            return chartOptionsObj;
        },
        isinPickerWidth() {
            return this.selectedIsin && this.chartDataLoaded ? 6 : 12;
        },
        investmentSum() {
            return parseInt(this.isinInfoObject.Scenarios.InitAmount);
        },
        isinInfoObject() {
            return this.getIsinInfoObject;
        },
        sri() {
            return this.isinInfoObject.Measurements.SRI;
        },
        rhp() {
            return this.isinInfoObject.Scenarios.RHP;
        },
        costIndicators() {
            let returnCostsVal = {
                gkis: 0,
                gkim: 0,
                gkif: 0,
                gkisVal: 0,
                gkimVal: 0,
                gkifVal: 0,
            };
            if (this.isinInfoObject.Costs) {
                returnCostsVal.gkis =
                    NumberFormatConverter.convertNumberFormat(this.isinInfoObject.Costs.Short.Percentage * 100, 2);
                returnCostsVal.gkisVal = this.isinInfoObject.Costs.Short.Absolute;
                if (this.isinInfoObject.Costs.Mid) {
                    returnCostsVal.gkim =
                        NumberFormatConverter.convertNumberFormat(this.isinInfoObject.Costs.Mid.Percentage * 100, 2);
                    returnCostsVal.gkimVal = this.isinInfoObject.Costs.Mid.Absolute;
                }
                if (this.isinInfoObject.Costs.Long) {
                    returnCostsVal.gkif =
                        NumberFormatConverter.convertNumberFormat(this.isinInfoObject.Costs.Long.Percentage * 100, 2);
                    returnCostsVal.gkifVal = this.isinInfoObject.Costs.Long.Absolute;
                }
            }
            return returnCostsVal;
        },
        getSelectedIsin() {
            return this.selectedIsin;
        },
        /* This function changes the yAxes-labels of the perfScenarioChart
         from displaying the percentages of the perfScenarios and instead showing the ROI */
        chartYaxesCallback() {
            // eslint-disable-next-line no-unused-vars
            const f = (label, index, labels) => {
                switch (label) {
                    case 0:
                        return "0%";
                    default:
                        return NumberFormatConverter.convertNumberFormat(label, 2) + "%";
                }
            };
            return f;
        },
        /* This function changes the yAxes-labels of the perfScenarioChart
        from displaying the percentages of the perfScenarios and instead showing the ROI */
        chartYaxesCallbackAbsolutes() {
            // eslint-disable-next-line no-unused-vars
            const f = (label, index, labels) => {
                switch (label) {
                    default:
                        return Math.round(label) + "€";
                }
            };
            return f;
        },
        isins() {
            return this.getIsins;
        },
        isins2() {
            return this.getIsins2;
        },
    },
    methods: {
        convertRHPFormat(rhp) {
            const tradingYearPeriod = 252;
            const rhpTradingYears = Math.trunc(rhp / tradingYearPeriod);
            const rhpTradingDays = rhp % tradingYearPeriod;
            let rhpTradingYearsString;
            let rhpTradingDaysString;
            //TradingYearsString
            switch (rhpTradingYears) {
                case 0:
                    rhpTradingYearsString = "";
                    break;
                case 1:
                    rhpTradingYearsString = "" + rhpTradingYears + " " + this.$t('year');
                    break;
                default:
                    rhpTradingYearsString = "" + rhpTradingYears + " " + this.$t('years');
                    break;
            }
            //TradingDaysString
            switch (rhpTradingDays) {
                case 0:
                    rhpTradingDaysString = "";
                    break;
                case 1:
                    rhpTradingDaysString = "" + rhpTradingDays + " " + this.$t('day');
                    break;
                default:
                    rhpTradingDaysString = "" + rhpTradingDays + " " + this.$t('days');
                    break;
            }

            return rhpTradingYearsString + rhpTradingDaysString;
        },
        forceFileDownload(response, title) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", title);
            document.body.appendChild(link);
            link.click();
        },
        async downloadResultJson() {
            let conciergeDeskURL;
            let authUser;
            //For running in BaseApp
            if (this.$store.getters['csbaseAppIntegrationModule/deployedServices']) {
                conciergeDeskURL =
                    this.$store.getters['csbaseAppIntegrationModule/deployedServices'].csquantengine.parameters.CONCIERGE_DESK_URL;
                authUser = await this.$store.dispatch('csbaseAuthModule/getAuthenticatedUser');
            }
            // For running locally without BaseApp
            else if (process.env.VUE_APP_CONCIERGE_DESK_URL) {
                conciergeDeskURL = process.env.VUE_APP_CONCIERGE_DESK_URL;
                authUser = this.$store.getters.user;
            }
            if (conciergeDeskURL) {
                let url = conciergeDeskURL + "download/" + this.s3key + "?versionId=" + this.s3version;
                let headers = {Authorization: authUser.signInUserSession.idToken.jwtToken};
                axios({
                    method: "get",
                    url,
                    headers,
                    responseType: "arraybuffer",
                }).then((response) => {
                    this.forceFileDownload(response, this.s3key);
                }).catch((error) => Vue.console.error(error));
            }
        },
        querySelections(v) {
            this.loading = true;
            this.items = this.isins.filter((e) => {
                return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1;
            });
        },
        querySelections2(v) {
            this.loading = true;
            this.items = this.isins2.filter((e) => {
                return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1;
            });
        },
        updateData(selectedIsin) {
            this.$apollo.query(GraphqlHelper.getAvailableCalculationResults(selectedIsin)).then((response) => {
                const availableCalculationsResults = [];
                if (response.data.getAvailableCalculationResults.availableResults.length > 0) {
                    response.data.getAvailableCalculationResults.availableResults.forEach((el) => {
                        availableCalculationsResults.push(el);
                    });
                    this.selectedIsin2 = availableCalculationsResults[0].sk;
                    this.getIsins2 = availableCalculationsResults;
                }
                this.getIsinInfoObject = response.data.getAvailableCalculationResults.latestResult;
                this.updateChart(true);
            }).catch((error) => Vue.console.error(error));
        },
        updateData2(selectedIsin2) {
            this.$apollo.query(GraphqlHelper.getCalculationResult('INSTRUMENT#' + this.selectedIsin, selectedIsin2)).
                then((response) => {
                    this.s3key = response.data.getCalculationResult.S3.Key;
                    this.s3version = response.data.getCalculationResult.S3.VersionID;
                    if (this.selectedIsin2 !== this.isinInfoObject.SK) {
                        this.getIsinInfoObject = response.data.getCalculationResult;
                        this.updateChart(true);
                    }
                }).catch((error) => Vue.console.error(error));
        },
        // Fills the data[] and labels[] of the perfScenarioChart with values of the isinInfoObject
        updateChart(clearChartData) {
            if (this.chartDataLoaded) {
                return;
            }
            if (clearChartData) {
                this.chartData_LineChart_Absolutes = [];
                this.chartData_BarChart_Percentages = [];
                this.chartData = [];
                this.roiValues = [];
            }
            const chartColorsInput = [this.$csBasicsColors.csAccentRed, this.$csBasicsColors.csAccentOrange,
                this.$csBasicsColors.csAccentYellow, this.$csBasicsColors.csAccentGreen];
            const chartColors = [];
            const scenariosXAxisLabels = [];

            /* SET CHART DATA FOR BOTH AXES */
            if (this.isinInfoObject.Scenarios.Stress) {
                scenariosXAxisLabels.push('Stress_short');
                this.chartData_BarChart_Percentages.push(this.isinInfoObject.Scenarios.Stress.Short.Percentage * 100);
                this.chartData_LineChart_Absolutes.push(this.isinInfoObject.Scenarios.Stress.Short.Absolute);
                if (this.isinInfoObject.Scenarios.Stress.Mid) {
                    scenariosXAxisLabels.push('Stress_mid');
                    this.chartData_BarChart_Percentages.push(this.isinInfoObject.Scenarios.Stress.Mid.Percentage * 100);
                    this.chartData_LineChart_Absolutes.push(this.isinInfoObject.Scenarios.Stress.Mid.Absolute);
                }
                if (this.isinInfoObject.Scenarios.Stress.Long) {
                    scenariosXAxisLabels.push('Stress_long');
                    this.chartData_BarChart_Percentages.push(
                        this.isinInfoObject.Scenarios.Stress.Long.Percentage * 100);
                    this.chartData_LineChart_Absolutes.push(this.isinInfoObject.Scenarios.Stress.Long.Absolute);
                }
            }
            if (this.isinInfoObject.Scenarios.Unfavorable) {
                scenariosXAxisLabels.push('Unfavorable_short');
                this.chartData_BarChart_Percentages.push(
                    this.isinInfoObject.Scenarios.Unfavorable.Short.Percentage * 100);
                this.chartData_LineChart_Absolutes.push(this.isinInfoObject.Scenarios.Unfavorable.Short.Absolute);
                if (this.isinInfoObject.Scenarios.Unfavorable.Mid) {
                    scenariosXAxisLabels.push('Unfavorable_mid');
                    this.chartData_BarChart_Percentages.push(
                        this.isinInfoObject.Scenarios.Unfavorable.Mid.Percentage * 100);
                    this.chartData_LineChart_Absolutes.push(this.isinInfoObject.Scenarios.Unfavorable.Mid.Absolute);
                }
                if (this.isinInfoObject.Scenarios.Unfavorable.Long) {
                    scenariosXAxisLabels.push('Unfavorable_long');
                    this.chartData_BarChart_Percentages.push(
                        this.isinInfoObject.Scenarios.Unfavorable.Long.Percentage * 100);
                    this.chartData_LineChart_Absolutes.push(this.isinInfoObject.Scenarios.Unfavorable.Long.Absolute);
                }
            }
            if (this.isinInfoObject.Scenarios.Moderate) {
                scenariosXAxisLabels.push('Moderate_short');
                this.chartData_BarChart_Percentages.push(this.isinInfoObject.Scenarios.Moderate.Short.Percentage * 100);
                this.chartData_LineChart_Absolutes.push(this.isinInfoObject.Scenarios.Moderate.Short.Absolute);
                if (this.isinInfoObject.Scenarios.Moderate.Mid) {
                    scenariosXAxisLabels.push('Moderate_mid');
                    this.chartData_BarChart_Percentages.push(
                        this.isinInfoObject.Scenarios.Moderate.Mid.Percentage * 100);
                    this.chartData_LineChart_Absolutes.push(this.isinInfoObject.Scenarios.Moderate.Mid.Absolute);
                }
                if (this.isinInfoObject.Scenarios.Moderate.Long) {
                    scenariosXAxisLabels.push('Moderate_long');
                    this.chartData_BarChart_Percentages.push(
                        this.isinInfoObject.Scenarios.Moderate.Long.Percentage * 100);
                    this.chartData_LineChart_Absolutes.push(this.isinInfoObject.Scenarios.Moderate.Long.Absolute);
                }
            }
            if (this.isinInfoObject.Scenarios.Favorable) {
                scenariosXAxisLabels.push('Favorable_short');
                this.chartData_BarChart_Percentages.push(
                    this.isinInfoObject.Scenarios.Favorable.Short.Percentage * 100);
                this.chartData_LineChart_Absolutes.push(this.isinInfoObject.Scenarios.Favorable.Short.Absolute);
                if (this.isinInfoObject.Scenarios.Favorable.Mid) {
                    scenariosXAxisLabels.push('Favorable_mid');
                    this.chartData_BarChart_Percentages.push(
                        this.isinInfoObject.Scenarios.Favorable.Mid.Percentage * 100);
                    this.chartData_LineChart_Absolutes.push(this.isinInfoObject.Scenarios.Favorable.Mid.Absolute);
                }
                if (this.isinInfoObject.Scenarios.Favorable.Long) {
                    scenariosXAxisLabels.push('Favorable_long');
                    this.chartData_BarChart_Percentages.push(
                        this.isinInfoObject.Scenarios.Favorable.Long.Percentage * 100);
                    this.chartData_LineChart_Absolutes.push(this.isinInfoObject.Scenarios.Favorable.Long.Absolute);
                }
            }

            /* SET ROI VALUES */
            this.chartData_LineChart_Absolutes.forEach(value => {
                this.roiValues.push("" + NumberFormatConverter.convertNumberFormat(value, 2) + "€");
            });
            /* Set chart colors */
            scenariosXAxisLabels.forEach(value => {
                if (value.startsWith("Stress")) {
                    chartColors.push(chartColorsInput[0]);
                } else if (value.startsWith("Unfavorable")) {
                    chartColors.push(chartColorsInput[1]);
                } else if (value.startsWith("Moderate")) {
                    chartColors.push(chartColorsInput[2]);
                } else if (value.startsWith("Favorable")) {
                    chartColors.push(chartColorsInput[3]);
                } else {
                    Vue.console.info(chartColors);
                }
            });
            let labelsTranslated = [];
            scenariosXAxisLabels.forEach(label => {
                labelsTranslated.push(this.$t(label));
            });
            //Set chart data
            this.chartData = {
                labels: labelsTranslated,
                datasets: [
                    {
                        data: this.chartData_LineChart_Absolutes,
                        fill: false,
                        type: 'line',
                        borderColor: this.$csBasicsColors.csDark,
                        datalabels: {
                            // display labels for this specific dataset
                            display: false
                        },
                        backgroundColor: chartColors,
                        yAxisID: "right-y-axis",
                    },
                    {
                        data: this.chartData_BarChart_Percentages,
                        fill: false,
                        borderColor: chartColors,
                        backgroundColor: chartColors,
                        yAxisID: "left-y-axis",
                    }
                ],
            };
            // let max = Math.max(...this.chartData_BarChart_Percentages);
            // let min = Math.min(...this.chartData_BarChart_Percentages);
            // this.chartOptions.scales.yAxes[0].ticks.max = max;
            // this.chartOptions.scales.yAxes[0].ticks.min = min;
            this.chartOptions.scales.yAxes[0].ticks.callback = this.chartYaxesCallback;
            this.chartOptions.scales.yAxes[0].ticks.stepSize = 5;

            // let maxAbsolutes = Math.max(...this.chartData_LineChart_Absolutes);
            // let minAbsolutes = Math.min(...this.chartData_LineChart_Absolutes);
            // this.chartOptions.scales.yAxes[1].ticks.max = maxAbsolutes;
            // this.chartOptions.scales.yAxes[1].ticks.min = minAbsolutes;
            this.chartOptions.scales.yAxes[1].ticks.callback = this.chartYaxesCallbackAbsolutes;
            this.chartDataLoaded = true;
        },
        updateIsinList(newIsinList) {
            this.getIsins = newIsinList.isins;
        },
        convertStatusTime(statusTime) {
            return new Date(statusTime * 1000).toLocaleString();
        },
        getAvailableIsinTimestamp(isinObject) {
            return new Date(isinObject.timestamp * 1000).toLocaleString();
        },
    },
    watch: {
        search(val) {
            val && val !== this.selectedIsin && this.querySelections(val);
        },
        search2(val) {
            val && val !== this.selectedIsin2 && this.querySelections2(val);
        },
        selectedIsin(val) {
            if (val) {
                this.chartDataLoaded = false;
                this.updateData(val);
            }
        },
        selectedIsin2(val) {
            if (val) {
                if (this.selectedIsin2 !== this.isinInfoObject.SK) {
                    this.chartDataLoaded = false;
                }
                this.updateData2(val);
            }
        },
        listCalculatedISINs(val) {
            if (val) {
                this.updateIsinList(val);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/**
* RESULTS COMPONENT STYLE
*/
@import "../../styles/main";

.isinPickerWrapper {
    width: 75%;
    margin-left: 10%;
    margin-right: 15%;

    .isinPickerWrapperRow {
        width: 100%;
        margin: 0;
        text-align: center;
    }
}

/* GRID LAYOUT */
#resultGrid {
    display: flex;
    /* These lines can affect the second row of elements to be misplaced */
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    list-style-type: none;

    #resultColumn1List {
        list-style-type: none;
    }
}

.resultGridPresenter {
    #resultGridPresenterIcon {
        font-size: 60px !important;
    }

    #resultGridPresenterTitle {
        color: $cs-accent-grey;
        font-weight: normal !important;
    }
}

.progressCircleContainer {
    text-align: center;
}

.resultColumn1 {
    width: 40%;
    padding-right: 12px;
}

.resultColumn2 {
    width: 30%;
    margin: 0 1%;
    padding-left: 12px;
}

.resultColumn3 {
    width: 25%;
    margin: 0 1%;
}

//Above resultColumn1-3
.isinPickerContainerStyle_Right {
    margin-left: auto;
    width: 60%;
}

//Above resultColumn1-3
.isinPickerContainerStyle_Central {
    margin: 0 auto;
    width: 30%;
}

.statusContainer {
    margin-top: 2%;
}

.sriContainer {
    margin-top: 2%;
}

.costIndicatorContainer {
    margin-left: auto;
}

.statusWidgetCard {
    width: 80%;
    margin-left: auto;
}

/* SRI BUTTONS CONTAINER LAYOUT */
.sriButton {
    margin-right: 2px !important;
    margin-top: auto;
    min-width: 2.5% !important;
    margin-bottom: auto;
    background-color: $cs-background !important;
}

.sriButton:first-of-type {
    min-width: 10px !important; /* has to be min width, changing width directly not possible */
}

.sriButton:last-of-type {
    margin-right: 0 !important;
    min-width: 10px !important; /* has to be min width, changing width directly not possible */
}

.sriButtonSelected {
    cursor: auto !important;
    margin-right: 2px !important;
    background-color: $cs-accent-yellow !important;
}

.sriButtonSelected:hover {
    cursor: auto !important;
}

.sriButtonSelected:before {
    opacity: 0 !important;
}

.sriButtonRating {
    color: black !important;
    font-size: 22px;
    font-weight: 400 !important;
}

.sriButtonRatingSelected {
    color: black !important;
    font-size: 30px;
    font-weight: 700 !important;
}
</style>
