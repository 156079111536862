<i18n src='./lang.json'></i18n>
<template>
    <cs-widget-card class="inflightCalculations" :hasContextMenu="false" :widgetCardTitle="$t('inflightCalculations')"
                    :widget-card-text-wrapper-style="{'padding-top': '55px'}"
    >
        <template slot="widgetCardText">
            <v-list v-if="loading">
                <cs-progress-circular :progressColor="$csBasicsColors.csPrimaryDarkBlue"></cs-progress-circular>
            </v-list>
            <v-list v-else two-line>
                <CSTrendIndicator :indicator="inflightCalculations.core"
                                  :indicatorSubTitle="$t('coreCalculations')"></CSTrendIndicator>
                <CSTrendIndicator :indicator="inflightCalculations.extdc"
                                  :indicatorSubTitle="$t('extendedDataControl')"></CSTrendIndicator>
            </v-list>
        </template>
    </cs-widget-card>
</template>
<script>
import GraphqlHelper from "@/js-helpers/graphqlHelper";
import CSTrendIndicator from "@/components/general/cs-trend-indicator";

export default {
    components: {CSTrendIndicator},
    name: "inflightCalculations",
    data: () => ({
        getInFlights: {
            core: 0,
            extdc: 0,
        },
        loading: true,
    }),

    apollo: {
        $client: 'CSQUANTENGINE_CLIENT', //Define which AppSyncClient to use for the apollo queries
        getInFlightStats: {
            query: GraphqlHelper.getInFlightStats(),
            pollInterval: 1000,
        },
    },
    methods: {
        updateInFlightStats(val) {
            this.getInFlights = val;
            this.loading = false;
        },
    },
    computed: {
        inflightCalculations() {
            return this.getInFlights;
        },
    },
    watch: {
        getInFlightStats(val) {
            this.updateInFlightStats(val);
        },
    },
};
</script>

<style>
.inflightCalculations {
    width: 80%;
    margin-left: auto;
}
</style>