import gql from 'graphql-tag';

export const AVAILABLE_CALCULATIONS_QUERY = gql`         
      query getAvailableCalculationResults($identifier: String!) {
        getAvailableCalculationResults(identifier: $identifier) {
    availableResults {
      sk
      timestamp
    }
    latestResult {
      Costs {
        Long {
          Absolute
          Percentage
        }
        Mid {
          Absolute
          Percentage
        }
        Short {
          Absolute
          Percentage
        }
      }
      DataControl {
        CRITICAL
        INFO
        WARNING
      }
      General {
        CalculationID
        Generation
        ISIN
        Status
        Timestamp
        User
      }
      Measurements {
        CRM
        MRM
        MRMapplied
        SRI
      }
      PK
      SK
      S3 {
        Key
        VersionID
      }
      Scenarios {
        InitAmount
        RHP
        Favorable {
          Long {
            Absolute
            Percentage
          }
          Mid {
            Absolute
            Percentage
          }
          Short {
            Absolute
            Percentage
          }
        }
        Moderate {
          Long {
            Absolute
            Percentage
          }
          Mid {
            Absolute
            Percentage
          }
          Short {
            Absolute
            Percentage
          }
        }
        Stress {
          Long {
            Absolute
            Percentage
          }
          Mid {
            Absolute
            Percentage
          }
          Short {
            Absolute
            Percentage
          }
        }
        Unfavorable {
          Long {
            Absolute
            Percentage
          }
          Mid {
            Absolute
            Percentage
          }
          Short {
            Absolute
            Percentage
          }
        }
      }
    }
  }
}
`;
export const AVAILABLE_CALCULATIONS_VARIABLES = ["identifier"];