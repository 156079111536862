import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0aac2954&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./lang.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc775689249%2Fsrc%2Fgit-codecommit.eu-central-1.amazonaws.com%2Fv1%2Frepos%2Fcsquantengine-frontend%2Fsrc%2Fviews%2Fcs-dashboard%2Findex.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports