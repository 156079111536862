/**
 * Providing default number format conversions
 */

module.exports = {

    /**
     * This method converts a number so that thousands are separated by a character. 
     * Especially for German settings, the thousands are normally separated by ',' instead of a '.'. 
     * Also the character between the digits before and after 0 is changed accordingly.
     * With the "toLocaleString" function used within this method the setting of the users machine is considered.
     * @param {*} numberToConvert number that should be converted
     * @param {*} minimumFractionDigits min number of digits after the comma
     * @param {*} maximumFractionDigits max number of digits after the comma
     * @returns {String} converted number (output is a string).
     */
    convertNumberFormat(numberToConvert, minimumFractionDigits, maximumFractionDigits) {
        let maximumFractionDigitsVal = maximumFractionDigits ? maximumFractionDigits : 2;
        let minimumFractionDigitsVal = minimumFractionDigits ? minimumFractionDigits : 0;
        if (minimumFractionDigitsVal > maximumFractionDigitsVal) {
            maximumFractionDigitsVal = minimumFractionDigitsVal;
        }
        return numberToConvert.toLocaleString(
            undefined, // undefined = use the browser's locale, string like 'en-US' = override locale.
            { minimumFractionDigits: minimumFractionDigitsVal, maximumFractionDigits: maximumFractionDigitsVal }
        );
    },
};
