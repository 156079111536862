const Dashboard = () => import(/* webpackChunkName: "csquantengineOld_dashboard" */ '../views/cs-dashboard');
const DataUpload = () => import(/* webpackChunkName: "csquantengineOld_dataUpload" */ '../views/cs-data-upload');
const Results = () => import(/* webpackChunkName: "csquantengineOld_results" */ '../views/cs-results');
/* NAMEs sollten Prefix besitzen für App */
const CSQUANTENGINE_ROUTES =
    [
        {
            path: '/quantengineOld/dashboard',
            name: 'CSQuantengineOld_Dashboard',
            component: Dashboard,
            meta: {serviceKey: 'CSQuantengineOld'},
        },
        {
            path: '/quantengineOld/data-upload',
            name: 'CSQuantengineOld_DataUpload',
            component: DataUpload,
            meta: {serviceKey: 'CSQuantengineOld'},
        },
        {
            path: '/quantengineOld/results',
            name: 'CSQuantengineOld_Results',
            component: Results,
            meta: {serviceKey: 'CSQuantengineOld'},
        },
    ];
export default CSQUANTENGINE_ROUTES;