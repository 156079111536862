import {
    AVAILABLE_CALCULATIONS_QUERY,
    AVAILABLE_CALCULATIONS_VARIABLES
} from '@/graphql/getAvailableCalculationResults';
import { MARK_ALERT_AS_READ_MUTATION, MARK_ALERT_AS_READ_VARIABLES } from '@/graphql/markAlertAsRead';
import { CALCULATION_RESULT_QUERY, CALCULATION_RESULT_VARIABLES } from '@/graphql/getCalculationResult';
import { CALCULATION_STATS_QUERY, CALCULATION_STATS_VARIABLES } from '@/graphql/getCalculationStats';
import { UPLOAD_HISTORY_QUERY } from '@/graphql/getUploadHistory';
import { ALERT_LIST_QUERY } from '@/graphql/getAlertList';
import { ALERT_STATE_QUERY } from '@/graphql/getAlertState';
import { BACKEND_VERSION_QUERY } from '@/graphql/getBackendVersion';
import { HEALTH_CHECK_STATUS } from '@/graphql/getHealthCheck';
import { INFLIGHT_STATS_QUERY } from '@/graphql/getInFlightStats';
import { LIST_USER_GROUPS_QUERY } from '@/graphql/listUserGroups';
import { LIST_CALCULATED_ISINS_QUERY } from '@/graphql/getListCalculatedIsins';
import { USER_LIST_QUERY } from '@/graphql/getUserList';
import { DELETE_USER_MUTATION, DELETE_USER_VARIABLES } from '@/graphql/deleteUser';
import { USER_INFORMATION_QUERY, USER_INFORMATION_VARIABLES } from '@/graphql/getUserInformation';
import { UPDATE_USER_MUTATION, UPDATE_USER_VARIABLES } from '@/graphql/updateUser';
import { INSERT_NEW_USER_MUTATION, INSERT_NEW_USER_VARIABLES } from '@/graphql/insertNewUser';
import { ST_ELMO_QUERY } from "@/graphql/callStElmo";
import { UPLOAD_HISTORY_SUBSCRIPTION } from "@/graphql/subscribeToUploadHistory";
import { GET_USERS_QUERY } from "@/graphql/getUsers";

/**
 * The methods within this file help building the graphlql requests that are sent with the ApolloClient to the
 * AppSyncApi.
 */
export default {
    /** REQUEST CONSTRUCTORS */

    /**
     * Query for retrieving the list of all users in the database.
     * @returns {*} Query request object.
     */
    getUsers() {
        return GET_USERS_QUERY;
    },
    /**
     * Query request object builder
     * @param {*} requestObject the actual request (e.g. query or mutation)
     * @param {*} variables variables JSON object used for the request
     * @param {*} pollInterval the polling interval of the request (in which frequence should apollo repeat this
     *     request)
     * @returns {*} the query request object.
     * For example: request = AVAILABLE_CALCULATIONS_QUERY, variables = { identifier: "identifier"}
     * will be built to { query: AVAILABLE_CALCULATIONS_QUERY, variables = { identifier: "identifier"} }
     */
    getGraphqlQueryObject(request, variables, pollInterval) {
        if (pollInterval) {
            return {query: request, variables: variables, pollInterval: pollInterval};
        } else {
            return {query: request, variables: variables};
        }
    },
    /**
     * Mutation request object builder
     * @param {*} requestObject
     * @param {*} variables variables JSON object used for the request
     * @returns {*} the mutation request object.
     * For example: request = DELETE_USER_MUTATION, variables = { username: "username"}
     * will be built to { mutation: DELETE_USER_MUTATION, variables: { username: "username"} }
     */
    getGraphqlMutationObject(request, variables) {
        return {mutation: request, variables: variables};
    },
    /**
     *
     * @param {*} variableNames The array of variable names needed for the request (e.g. query or mutation).
     * Are equal to the key elements of the variables {} within the request.
     * @param {*} variableParameters The array of variables passed.
     * Are equal to the value elements of the variables {} within the request.
     * @returns {*} the variables JSON object for the request object.
     * For example: variableNames = UPDATE_USER_VARIABLES (equal to ["email", "family_name", "given_name", "group",
     *     "phone_number", "username"]), variableParameters = [email, family_name, given_name, group, phone_number,
     *     username] will be built to:
     * { 
     *  "email": email, "family_name": family_name, "given_name": given_name, 
     *  "group":group, "phone_number": phone_number, "username": username 
     * }
     */
    getVariablesParametersObject(variableNames, variableParameters) {
        let variables = {};
        if (variableNames.length === variableParameters.length) {
            for (let index = 0; index < variableNames.length; index++) {
                variables[variableNames[index]] = variableParameters[index];
            }
        }
        return variables;
    },

    /** REQUEST CONSTRUCTORS INPUTS */

    /**
     * For a certain ISIN, a list of available results and a JSON object containing the latest result is retrieved.
     * @param {*} identifier the ISIN identifier of interest (= request parameter for the query
     *     'getAvailableCalculationResults').
     * @returns {*} Query request object for the passed parameter variables. If no variable is provided, the query
     *     request object only includes the graphql query without variables. For example: { query:
     *     AVAILABLE_CALCULATIONS_QUERY } instead of
     * { query: AVAILABLE_CALCULATIONS_QUERY, variables: { identifier: "identifier" } }
     */
    getAvailableCalculationResults(identifier) {
        if (!identifier) {
            return AVAILABLE_CALCULATIONS_QUERY;
        }
        let variableParameters = [identifier];
        return this.getGraphqlQueryObject(AVAILABLE_CALCULATIONS_QUERY,
            this.getVariablesParametersObject(AVAILABLE_CALCULATIONS_VARIABLES, variableParameters));
    },
    /**
     * Query for retrieving the calculation result for a certain identifier and sk.
     * @param {*} identifier the ISIN identifier
     * @param {*} sk sk of DynamoDB
     * @returns {*} Query request object for the passed parameter variables. If no variable is provided, the query
     *     request object only includes the graphql query without variables.
     */
    getCalculationResult(identifier, sk) {
        if (!identifier, !sk) {
            return CALCULATION_RESULT_QUERY;
        }
        let variableParameters = [identifier, sk];
        return this.getGraphqlQueryObject(CALCULATION_RESULT_QUERY,
            this.getVariablesParametersObject(CALCULATION_RESULT_VARIABLES, variableParameters));
    },
    /**
     * Mutation for marking an alert as read (will be deleted and not shown when retrieving the alertList).
     * @param {*} alertId Alert ID
     * @param {*} alertType Alert Type (e.g. "WARNING" or "ERROR")
     * @returns {*} Mutation request object for the passed variables. If no variable is provided, the mutation request
     *     object only includes the graphql mutation without variables
     */
    markAlertAsRead(alertId, alertType) {
        if (!alertId, !alertType) {
            return MARK_ALERT_AS_READ_MUTATION;
        }
        let variableParameters = [alertId, alertType];
        /* readAlert is the key for the variables alertId and alertType */
        let variables = {
            readAlert: this.getVariablesParametersObject(MARK_ALERT_AS_READ_VARIABLES, variableParameters)
        };
        return this.getGraphqlMutationObject(MARK_ALERT_AS_READ_MUTATION, variables);
    },
    /**
     * Query for retrieving the calculation stats for a certain timeframe.
     *
     * @param {*} Period The period unit (ENUM: "SECONDS", "MINUTES", "HOURS", "DAYS")
     * @param {*} numberPeriods The number of periods for which past calculations should be retrieved.
     * For example: Period="HOURS", numberPeiods=24
     * This variable combination will tell the graphql api that the calculation stats of the last 24 hours should be
     *     retrieved
     * (e.g. amount of calculations).
     * @returns {*} Query request object for the passed parameter variables. If no variable is provided, the query
     *     request object only includes the graphql query without variables
     */
    getCalculationStats(Period, numberPeriods, pollInterval) {
        if (!Period, !numberPeriods) {
            return CALCULATION_STATS_QUERY;
        }
        let variableParameters = [Period, numberPeriods];
        return this.getGraphqlQueryObject(CALCULATION_STATS_QUERY,
            this.getVariablesParametersObject(CALCULATION_STATS_VARIABLES, variableParameters), pollInterval);
    },
    /**
     * Query for retrieving the list of the upload history.
     * @returns {*} Query request object.
     */
    getUploadHistoryQuery() {
        return UPLOAD_HISTORY_QUERY;
    },
    /**
     * Query for retrieving the list of all alerts (unread).
     * @returns {*} Query request object.
     */
    getAlertList() {
        return ALERT_LIST_QUERY;
    },
    /**
     * Query for retrieving the alert state containing the state and the amount of alerts.
     * @returns {*} Query request object.
     */
    getAlertState() {
        return ALERT_STATE_QUERY;
    },
    /**
     * Query for retrieving the version of the CSQuantEngine (backend).
     * @returns {*} Query request object.
     */
    getBackendVersion() {
        return BACKEND_VERSION_QUERY;
    },
    /**
     * Query for retrieving the status of the CSQuantEngine (backend).
     * @returns {*} Query request object.
     */
    getHealthCheckStatus() {
        return HEALTH_CHECK_STATUS;
    },
    /**
     * Query for retrieving the ongoing calculations.
     * @returns {*} Query request object.
     */
    getInFlightStats() {
        return INFLIGHT_STATS_QUERY;
    },
    /**
     * Query for retrieving the list of all user groups.
     * @returns {*} Query request object.
     */
    getListUserGroups() {
        return LIST_USER_GROUPS_QUERY;
    },
    /**
     * Query for retrieving the list of all isins in the database for which at least one calculation exists.
     * @returns {*} Query request object.
     */
    getListCalculatedISINs() {
        return LIST_CALCULATED_ISINS_QUERY;
    },
    /**
     * Query for retrieving the list of all users in the database.
     * @returns {*} Query request object.
     */
    getUserListQuery() {
        return USER_LIST_QUERY;
    },
    /**
     * Query for warming up backend functions.
     * @returns SUCCESS or FAILED.
     */
    callStElmo() {
        return ST_ELMO_QUERY;
    },
    /**
     * Mutation for deleting an user.
     * @param {*} username
     * @returns {*} Mutation request object for the passed parameter variables. If no variable is provided, the
     *     mutation request object only includes the graphql mutation without variables
     */
    deleteUser(username) {
        if (!username) {
            return DELETE_USER_MUTATION;
        }
        let variableParameters = [username];
        return this.getGraphqlMutationObject(DELETE_USER_MUTATION,
            this.getVariablesParametersObject(DELETE_USER_VARIABLES, variableParameters));
    },
    /**
     * Query for retrieving the user information for a specific user.
     * @param {*} username Username
     * @returns {*} Query request object for the passed parameter variables. If no variable is provided, the query
     *     request object only includes the graphql query without variables
     */
    getUserInformation(username) {
        if (!username) {
            return USER_INFORMATION_QUERY;
        }
        let variableParameters = [username];
        return this.getGraphqlQueryObject(USER_INFORMATION_QUERY,
            this.getVariablesParametersObject(USER_INFORMATION_VARIABLES, variableParameters));
    },
    /**
     * Mutation for adding a new user to the cognito user pool.
     * @param {*} email New Email address for the user
     * @param {*} family_name New Family name
     * @param {*} given_name New First name
     * @param {*} group New User group
     * @param {*} phone_number New Phone number
     * @param {*} username New Username
     * @returns {*} Mutation request object for the passed parameter variables. If no variable is provided, the
     *     mutation request object only includes the graphql mutation without variables
     */
    updateUser(email, family_name, given_name, groups, phone_number, username) {
        if (!email && !family_name && !given_name && !groups && !phone_number && !username) {
            return UPDATE_USER_MUTATION;
        }
        let variableParameters = [email, family_name, given_name, groups, phone_number, username];
        return this.getGraphqlMutationObject(UPDATE_USER_MUTATION,
            this.getVariablesParametersObject(UPDATE_USER_VARIABLES, variableParameters));
    },
    /**
     * Mutation for adding a new user to the cognito user pool.
     * @param {*} email Email address for the new user
     * @param {*} family_name Family name
     * @param {*} given_name First name
     * @param {*} groups User groups
     * @param {*} phone_number Phone number
     * @param {*} username Username
     * @returns {*} Mutation request object for the passed parameter variables. If no variable is provided, the
     *     mutation request object only includes the graphql mutation without variables
     */
    insertNewUser(email, family_name, given_name, groups, phone_number, username) {
        if (!email && !family_name && !given_name && !groups && !phone_number && !username) {
            return INSERT_NEW_USER_MUTATION;
        }
        let variableParameters = [email, family_name, given_name, groups, phone_number, username];
        return this.getGraphqlMutationObject(INSERT_NEW_USER_MUTATION,
            this.getVariablesParametersObject(INSERT_NEW_USER_VARIABLES, variableParameters));
    },
    /**
     * Subscription for retrieving new elements of the upload history.
     * @returns {*} Query request object.
     */
    uploadHistorySubscription() {
        return UPLOAD_HISTORY_SUBSCRIPTION;
    }
};