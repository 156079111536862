<i18n src='./lang.json'></i18n>
<template>
    <v-list two-line>
        <v-list-item>
            <span class="indicator">{{ indicator }}</span>
            <v-list-item-content>
                <v-list-item-title v-if="indicatorTitle"
                >
                    <v-icon>{{ indicatorTitle }}</v-icon>
                </v-list-item-title
                >
                <v-list-item-subtitle v-if="indicatorSubTitle">{{ indicatorSubTitle }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>
<script>
export default {
    name: "CSTrendIndicator",
    props: {
        indicator: {
            type: [String, Number],
        },
        indicatorTitle: {
            type: String,
        },
        indicatorSubTitle: {
            type: String,
        },
    },
};
</script>
<style lang="scss">
.indicator {
    font-size: 50px;
    margin-right: 10px;
}
</style>