import gql from 'graphql-tag';

export const UPLOAD_HISTORY_SUBSCRIPTION = gql`
    subscription insertedHistoryItem {
        insertedHistoryItem {
            PK
            SK
            identifier
            s3_key
            s3_version
            technical_format
            timestamp
            username
            uuid
        }
    }
`;