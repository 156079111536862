<i18n src='./lang.json'></i18n>
<template>
    <cs-widget-card :widget-card-text-wrapper-style="{'padding-top': '55px'}"
                    @dialogOpened="dialog = true" :hasContextMenu="true" :widgetCardTitle="$t('calculationStats')">
        <template slot="widgetCardText">
            <cs-progress-circular v-if="!chartDataLoaded"
                                  :progressColor="$csBasicsColors.csPrimaryDarkBlue"></cs-progress-circular>
            <v-list-item v-if="emptyCalculationsArray">
                <v-list-item-icon class="mr-2">
                    <v-icon :color="$csBasicsColors.csPrimaryDarkBlue">{{ $csBasicsIcons.informationNew }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('emptyCalculationsArrayInformation') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <Barchart
                v-if="chartDataLoaded && !emptyCalculationsArray"
                :chartData="chartData"
                :options="chartOptions"
                :scaleLabel="this.PeriodCopy"
                :height="400"
            >
            </Barchart>
            <v-divider v-if="chartDataLoaded && !emptyCalculationsArray"/>
            <template slot="widgetCardDialog">
                <v-dialog persistent v-model="dialog" max-width="500">
                    <v-card>
                        <v-toolbar class="csGradientBlueBackground" dense>
                            <v-toolbar-title style="font-family: 'ubuntu' !important" class="title white--text"> {{
                                    $t('chooseCalculationsPeriod')
                                }}
                            </v-toolbar-title>
                        </v-toolbar>

                        <v-card-text class="text-justify" style="padding-top: 20px">
                            <form class="calculationStatsPicker">
                                <v-select
                                    v-model="PeriodCopy"
                                    :items="availablePeriods"
                                    :label="$t('period')"
                                    @input="$v.PeriodCopy.$touch()"
                                    @blur="$v.PeriodCopy.$touch()"
                                    required
                                ></v-select>
                                <v-select
                                    v-model="numberPeriodsCopy"
                                    :items="numberPeriodsPossible"
                                    :label="$t('numberOfPeriods')"
                                    required
                                    :error-messages="numberPeriodsCopyErrors"
                                    @input="$v.numberPeriodsCopy.$touch()"
                                    @blur="$v.numberPeriodsCopy.$touch()"
                                ></v-select>
                            </form>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-toolbar dense>
                            <v-spacer></v-spacer>
                            <v-btn :color="submitButtonColor" @click="submit" :disabled="disableSubmit"
                                   class="dialog-btn-yes" text>
                                {{ $t('submitCalculationStats') }}
                            </v-btn>
                            <v-btn :color="$csBasicsColors.csDark" @click="dialog = false;" class="dialog-btn-no"
                                   text>
                                {{ $t('cancel') }}
                            </v-btn>
                        </v-toolbar>
                    </v-card>
                </v-dialog>
            </template>
            <CSTrendIndicator
                v-if="chartDataLoaded && !emptyCalculationsArray"
                :indicator="totalCalculations"
                :indicatorSubTitle="totalCalculationsPeriodInformation"
                :indicatorTitle="totalTrend"
            ></CSTrendIndicator>
        </template>
    </cs-widget-card>
</template>

<script>
import Barchart from "@/components/charts/basic/BarChart";
import GraphqlHelper from "@/js-helpers/graphqlHelper";
import ChartHelper from "@/js-helpers/chartHelper";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import CSTrendIndicator from "@/components/general/cs-trend-indicator";
import ChartjsAnimations from "@/js-helpers/chartJsAnimations";

export default {
    name: "Calculations",
    components: {
        Barchart,
        CSTrendIndicator,
    },
    mixins: [validationMixin],
    validations: {
        PeriodCopy: {required},
        numberPeriodsCopy: {required},
    },
    data: () => ({
        dialog: false,
        chartDataLoaded: false,
        chartData: {},
        getTotalCalculations: 0,
        getTotalTrend: "",
        Period: "HOURS",
        PeriodCopy: "HOURS",
        numberPeriodsCopy: 24,
        numberPeriods: 24,
        availablePeriods: [],
        getNumberPeriodsPossible: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
            24],
        getEmptyCalculationsArray: false,
        getTotalCalculationsPeriodInformation: "24 hours",
        submitButtonClicked: false,
    }),
    apollo: {
        $client: 'CSQUANTENGINE_CLIENT', //Define which AppSyncClient to use for the apollo queries
        getCalculationsStats: {
            query: GraphqlHelper.getCalculationStats(),
            pollInterval: 60000,
            variables() {
                return {
                    Period: this.Period,
                    numberPeriods: this.numberPeriods,
                };
            },
        },
    },
    mounted() {
        if (this.language === 'de') {
            this.availablePeriods.push("Sekunden", "Minuten", "Stunden", "Tage");
        } else {
            this.availablePeriods.push("Seconds", "Minutes", "Hours", "Days");
        }
    },
    methods: {
        updateChart(val) {
            const chartDataInput = [];
            const chartLabels = [];
            const chartColors = [];
            let i = 0;
            let color = this.$csBasicsColors.csPrimaryLightBlue;
            val.calculations.forEach((el) => {
                chartDataInput.push(el);
                chartLabels.push("" + i);
                chartColors.push(color);
                i++;
            });
            this.getEmptyCalculationsArray = val.calculations.every((item) => item === 0);

            this.chartData = {
                labels: chartLabels.reverse(),
                datasets: [
                    {
                        data: chartDataInput,
                        fill: false,
                        borderColor: chartColors,
                        backgroundColor: chartColors,
                    },
                ],
            };

            this.chartDataLoaded = true;
        },
        updateTotalTrend(val) {
            let icon;
            if (val.trend === "UP") {
                icon = this.$csBasicsIcons.trendUp;
            } else if (val.trend === "DOWN") {
                icon = this.$csBasicsIcons.trendDown;
            } else {
                icon = this.$csBasicsIcons.trendStable;
            }
            this.getTotalTrend = icon;
        },
        updateTotalCalculations(val) {
            this.getTotalCalculations = val.total;
        },
        submit() {
            this.submitButtonClicked = true;
            this.$v.$touch();
            this.dialog = false;
            if (this.language === 'de') {
                if (this.PeriodCopy === 'Sekunden' || this.PeriodCopy === 'Minuten' || this.PeriodCopy === 'Stunden' ||
                    this.PeriodCopy === 'Tage') {
                    if (this.PeriodCopy === 'Sekunden') {
                        this.PeriodCopy = 'SECONDS';
                    }
                    if (this.PeriodCopy === 'Minuten') {
                        this.PeriodCopy = 'MINUTES';
                    }
                    if (this.PeriodCopy === 'Stunden') {
                        this.PeriodCopy = 'HOURS';
                    }
                    if (this.PeriodCopy === 'Tage') {
                        this.PeriodCopy = 'DAYS';
                    }
                }
            } else {
                if (this.PeriodCopy === 'Seconds' || this.PeriodCopy === 'Minutes' || this.PeriodCopy === 'Hours' ||
                    this.PeriodCopy === 'Days') {
                    if (this.PeriodCopy === 'Seconds') {
                        this.PeriodCopy = 'SECONDS';
                    }
                    if (this.PeriodCopy === 'Minutes') {
                        this.PeriodCopy = 'MINUTES';
                    }
                    if (this.PeriodCopy === 'Hours') {
                        this.PeriodCopy = 'HOURS';
                    }
                    if (this.PeriodCopy === 'Days') {
                        this.PeriodCopy = 'DAYS';
                    }
                }
            }

            this.$apollo.queries.getCalculationsStats.refetch({
                Period: this.PeriodCopy,
                numberPeriods: this.numberPeriodsCopy,
            }).then((response) => {
                this.updateChart(response);
            }).catch((error) => console.log(error));
        },
        updateNumberPeriodsPossible(val) {
            let n = 24;
            let helpArr = [];
            if (val === 'Seconds' || val === 'Minutes' || val === 'Sekunden' || val === 'Minuten') {
                n = 59;
            }
            if (val === 'Days' || val === 'Tage') {
                n = 7;
            }
            for (let i = 1; i <= n; i++) {
                helpArr.push(i);
            }
            this.getNumberPeriodsPossible = helpArr;
        },
    },
    computed: {
        /**
         * Returns the locale value from vuex store for the language flag.
         */
        language() {
            return this.$store.getters.locale;
        },
        chartOptions() {
            let chartOptionsObj = ChartHelper.getBasicChartOptions();
            let scaleLabelYAxes = this.$t('amountOfCalculations');
            chartOptionsObj.scales.yAxes[0].scaleLabel = ChartHelper.setScaleLabel(scaleLabelYAxes);
            let scaleLabelXAxes = this.$store.getters.locale === 'de'
                ? "" + this.$t('ago') + " n " + this.$t(this.periodCopyLowerCase)
                : "n " + this.$t(this.periodCopyLowerCase) + ' ' + this.$t('ago');
            chartOptionsObj.tooltips.enabled = false;
            chartOptionsObj.scales.xAxes[0].ticks.autoSkip = this.numberPeriodsCopy > 24;
            chartOptionsObj.scales.xAxes[0].ticks.beginAtZero = true;
            chartOptionsObj.scales.xAxes[0].scaleLabel = ChartHelper.setScaleLabel(scaleLabelXAxes);
            chartOptionsObj.animation = ChartHelper.setAnimation(1500, ChartjsAnimations.easeInOutElastic);
            return chartOptionsObj;
        },
        totalCalculations() {
            return this.getTotalCalculations;
        },
        totalTrend() {
            return this.getTotalTrend;
        },
        numberPeriodsPossible() {
            return this.getNumberPeriodsPossible;
        },
        emptyCalculationsArray() {
            return this.getEmptyCalculationsArray;
        },
        periodCopyLowerCase() {
            return this.PeriodCopy.toLowerCase();
        },
        disableSubmit() {
            return !(this.PeriodCopy && this.numberPeriodsCopy);
        },
        submitButtonColor() {
            return this.disableSubmit ? this.$csBasicsColors.csAccentGrey : this.$csBasicsColors.csPrimaryDarkBlue;
        },
        numberPeriodsCopyErrors() {
            const errors = [];
            if (!this.$v.numberPeriodsCopy.$dirty) {
                return errors;
            }
            !this.$v.numberPeriodsCopy.required && errors.push(this.$t('numberOfPeriods') + ' ' + this.$t('required'));
            return errors;
        },
        totalCalculationsPeriodInformation() {
            if (this.$store.getters.locale === 'de') {
                if (this.getTotalCalculationsPeriodInformation === '24 hours') {
                    return this.$t('lastCalculationsTotal') + this.numberPeriodsCopy + " " +
                        this.$t(this.periodCopyLowerCase);
                }
            }
            return this.$t('lastCalculationsTotal') + this.getTotalCalculationsPeriodInformation;
        },
    },
    watch: {
        language(val) {
            if (val) {
                this.availablePeriods = [];
                if (val === 'de') {
                    this.availablePeriods.push("Sekunden", "Minuten", "Stunden", "Tage");
                    this.getTotalCalculationsPeriodInformation =
                        "" + this.numberPeriodsCopy + " " + this.$t(this.periodCopyLowerCase);
                } else {
                    this.availablePeriods.push("Seconds", "Minutes", "Hours", "Days");
                    this.getTotalCalculationsPeriodInformation =
                        "" + this.numberPeriodsCopy + " " + this.$t(this.periodCopyLowerCase);
                }
            }
        },
        getCalculationsStats(val) {
            if (val) {
                this.updateChart(val);
                this.updateTotalCalculations(val);
                this.updateTotalTrend(val);
            }
        },
        PeriodCopy(val) {
            if (!this.submitButtonClicked) {
                /* When the user has changed the settings for the chosen period, the numberPeriodsPossible will change
                and the v-select will not show a selected number of period, but actually it still has the last selected period.
                So therefore we set the numberPeriod to null in order to prevent that the user sets up a call with
                e.g. PERIOD: "DAYS", numberPeriods: 59 */
                this.numberPeriodsCopy = null;
                this.updateNumberPeriodsPossible(val);
            }
        },
        /* This watcher logic shall update the information besides the totalCalculations count
        only when the user has closed the chart settings actually submitted a call to update the chart */
        dialog(val) {
            if (!val) {
                if (this.submitButtonClicked) {
                    this.getTotalCalculationsPeriodInformation =
                        "" + this.numberPeriodsCopy + " " + this.$t(this.periodCopyLowerCase);
                }
            } else {
                this.submitButtonClicked = false;
            }
        },
    },
};
</script>

<style lang="scss">

#settingsButtonIcon {
    margin: 0 auto !important;
    color: $cs-primary-dark-blue !important;
}

.calculationStatsPicker {
    background: white;
}
</style>
