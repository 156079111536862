import gql from 'graphql-tag';

export const CALCULATION_RESULT_QUERY = gql`
  query MyQuery($identifier: String!, $sk: String!) {
  getCalculationResult(pk: $identifier, sk: $sk) {
    Costs {
      Long {
        Absolute
        Percentage
      }
      Mid {
        Absolute
        Percentage
      }
      Short {
        Absolute
        Percentage
      }
    }
    DataControl {
      CRITICAL
      INFO
      WARNING
    }
    General {
      CalculationID
      Generation
      ISIN
      Status
      Timestamp
      User
    }
    Measurements {
      CRM
      MRM
      MRMapplied
      SRI
    }
    PK
    S3 {
      Key
      VersionID
    }
    SK
    Scenarios {
      Favorable {
        Long {
          Absolute
          Percentage
        }
        Mid {
          Absolute
          Percentage
        }
        Short {
          Absolute
          Percentage
        }
      }
      InitAmount
      Moderate {
        Long {
          Absolute
          Percentage
        }
        Mid {
          Absolute
          Percentage
        }
        Short {
          Absolute
          Percentage
        }
      }
      RHP
      Stress {
        Long {
          Absolute
          Percentage
        }
        Mid {
          Absolute
          Percentage
        }
        Short {
          Absolute
          Percentage
        }
      }
      Unfavorable {
        Long {
          Absolute
          Percentage
        }
        Mid {
          Absolute
          Percentage
        }
        Short {
          Absolute
          Percentage
        }
      }
    }
  }
}
`;
export const CALCULATION_RESULT_VARIABLES = ["identifier", "sk"];
