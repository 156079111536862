<i18n src='./lang.json'></i18n>
<template>
    <cs-widget-card :widgetCardTitle="$t('healthCheck')" class="healthCheck mt-5" :hasContextMenu="false"
                    :widget-card-text-wrapper-style="{'padding-top': '55px'}"
    >
        <template slot="widgetCardText"
        >
            <v-list>
                <v-list-item v-if="loading">
                    <cs-progress-circular :progressColor="$csBasicsColors.csPrimaryDarkBlue"></cs-progress-circular>
                </v-list-item>
                <v-list-item v-else>
                    <v-icon id="healthStatusIcon" x-large v-bind:style="healthStatusObject.color">
                        {{ healthStatusObject.icon }}
                    </v-icon>
                    <span v-bind:style="healthStatusObject.color"
                          id="healthStatusInformation">{{ healthStatusObject.info }}</span>
                </v-list-item>
            </v-list>
        </template
        >
    </cs-widget-card>
</template>

<script>
import GraphqlHelper from "@/js-helpers/graphqlHelper";

export default {
    name: "healthCheck",
    data: () => ({
        getHealthStatus: 0,
        getHealthStatusObject: {},
        loading: true,
    }),

    apollo: {
        $client: 'CSQUANTENGINE_CLIENT', //Define which AppSyncClient to use for the apollo queries
        getHealthCheckStatus: {
            query: GraphqlHelper.getHealthCheckStatus(),
            pollInterval: 60000,
        },
    },
    methods: {
        updateHealthStatusObject(val) {
            this.getHealthStatus = val.healthcheckstatus;
            let returnObj = {color: "", icon: "", info: ""};
            if (val.healthcheckstatus === "HEALTHY") {
                returnObj.color = "color: " + this.$csBasicsColors.csAccentGreen;
                returnObj.icon = this.$csBasicsIcons.successNew;
                returnObj.info = this.$t('stableSystem');
            } else if (val.healthcheckstatus === "UNSTABLE") {
                returnObj.color = "color: " + this.$csBasicsColors.csAccentOrange;
                returnObj.icon = this.$csBasicsIcons.warningNew;
                returnObj.info = this.$t('unstableSystem');
            } else {
                returnObj.color = "color: " + this.$csBasicsColors.csAccentRed;
                returnObj.icon = this.$csBasicsIcons.errorNew;
                returnObj.info = this.$t('unavailableSystem');
            }
            this.getHealthStatusObject = returnObj;
            this.loading = false;
        },
    },
    computed: {
        healthStatusObject() {
            return this.getHealthStatusObject;
        },
        healthStatus() {
            return this.getHealthStatus;
        },
    },
    watch: {
        getHealthCheckStatus(val) {
            this.updateHealthStatusObject(val);
        },
    },
};
</script>

<style>
.healthCheck {
    width: 85%;
    margin-left: auto;
    margin-bottom: 5%;
}

#healthStatusIcon {
    margin-right: 5px;
    font-size: 50px;
}

#healthStatusInformation {
    font-size: 16px;
}
</style>